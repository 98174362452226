import styled, {createGlobalStyle} from 'styled-components';
import {Link} from "gatsby";
import Img from "gatsby-image"
import {GlobalFonts} from "../styles/fonts";
import {GlobalBackgroundColors, GlobalColors} from "../styles/GlobalStyles";
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const GlobalStyleColumns = createGlobalStyle`
  .column {
    
  }
  .cols-cont-images {
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .col-cont-image {
    flex-basis: auto;
    
    @media(min-width: 300px) and (max-width: 767px){
      width: 50%;
      height: 343px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      width: 33%;
      height: 343px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      width: 20%;
      height: 343px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      width: 20%;
      height: 343px;
    }
    @media(min-width: 1408px){
      width: 20%;
      height: 343px;
    }
  }
  
  @media screen and (min-width: 300px){
    .columns:not(.is-desktop) {
      display: flex !important;
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .max-height-col{
      height: 25%;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .max-height-col{
      height: 25%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .max-height-col{
      height: 25%;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .max-height-col{
      height: 200px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .max-height-col{
      height: 200px;
    }
  }
  @media(min-width: 1920px){
    .max-height-col{
      height: 220px;
    }
  }
`

export const ContBackObras = styled.div`
  width: 100%;
  height: 100px;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-left: 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-left: 0;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-left: 0;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-left: 0;
  }
  @media(min-width: 1408px){
    padding-left: 0;
  }
`
export const LinkGatsby = styled(Link)`
  width: 320px;
  color: ${GlobalColors.colorPrimary};
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const MyAniLink = styled(AniLink)`
  width: 320px;
  color: ${GlobalColors.colorPrimary};
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const ContIcoBack = styled.div`
  width: 45px;
  height: 45px;
`
export const ContTecnicaObra = styled.div`
  margin-left: 15px;
  
  font-family: ${GlobalFonts.fontMedium};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 40px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 40px;
  }
  @media(min-width: 1408px){
    font-size: 40px;
  }
`
export const ContItem = styled.div`
  width: 100%;
  height: 100%;
  min-height: 150px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`
export const LinkObras = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorMenuSecondary};
  
  &:hover{
    background-color: rgba(237, 90, 88, .8);
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 22px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 24px;    
  }
  @media(min-width: 1408px){
    font-size: 28px;
  }
`
export const LinkObrasAniLink = styled(AniLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontMedium};
  /*color: ${GlobalColors.colorMenuSecondary};*/
  color: ${GlobalColors.colorMenuPrimary};
  
  -webkit-transition: all 0.7s 0s ease;
  -moz-transition: all 0.7s 0s ease;
  -o-transition: all 0.7s 0s ease;
  transition: all 0.7s 0s ease;
  
  &:hover{
    background-color: rgba(237, 90, 88, .8);
  }
  
  &:hover div{
    display: block;
    color: ${GlobalColors.colorMenuPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 32px;
    line-height: 32px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 32px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 34px;
    line-height: 34px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1408px){
    font-size: 38px;
    line-height: 38px;
  }
`
export const ContItemName = styled.div`
  padding: 10px 25px;
  
  display: none;
  
  /*background-color: ${GlobalBackgroundColors.bgSecondary};
  border-radius: 10px;*/
  text-align: center;
  
  opacity: 1;
`
export const Image = styled(Img)`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 343px;
`
