import  React from  "react";
import {graphql} from "gatsby"
import { Container, Columns, Column } from 'bloomer';
import Layout from "../components/Layout"
import Seo from "../components/seo";
import {GlobalStyleColumns, ContBackObras, ContItem, ContItemName, ContTecnicaObra, MyAniLink, LinkObrasAniLink} from "./stylesObrasPage";
import {GlobalBackgroundColors, GlobalStyle} from "../styles/GlobalStyles";
// import scrollTo from 'gatsby-plugin-smoothscroll';

export const query = graphql`
  query ( $subject_min: String! ) {
    allMysqlObras (
      filter: { subject_min: { eq: $subject_min } }
      sort: { fields: title, order: ASC } 
      ) {
      nodes {
        id
        id_obra
        status
        title
        subject
        subject_min
        url
        file_name
        image
      }
    }
    site {
      siteMetadata {
        env
      }
    }
  }
`;

const ObrasPage = ({data, location}) => {
  console.log('data => ', data);
  console.log('location => ', location);
  const { env } = data.site.siteMetadata;
  //console.log('env => ', env);

  let PATH_IMG = '../../images-obras';

  if(env === 'development'){
    PATH_IMG = 'http://localhost:8888/Monica-Castillo/monica-castillo-obras-all';
  }

  const obras = data.allMysqlObras;
  console.log('obras => ', obras);

  const tecnicaObras = obras.nodes[0].subject;
  //console.log('tecnicaObras => ', tecnicaObras);

  const fnGoToElement = (el) => {
    //console.log('el =>', el);
    setTimeout(function(){
      // scrollTo(`#${el}`);
    }, 2000);
  }
  if(location.state){
    if(location.state.obra){
      //console.log('location.state.obra =>', location.state.obra);
      fnGoToElement(location.state.obra)
    }
  }

  return (
    <Layout
      themeColor={'light'}
      clsOverflow={true}
    >
      <Seo
        title={`${tecnicaObras} | Obras`}
        description=""
        works=''
        ogImage={`obras_f.jpg`}
        twitterImage={`obras_t.jpg`}
      />

      <GlobalStyle/>
      <GlobalStyleColumns/>

      <Container>
        <ContBackObras>
          <MyAniLink
            cover
            bg={GlobalBackgroundColors.bgContentLight}
            to={'/obras'}
          >
            <ContTecnicaObra>
              {tecnicaObras}
            </ContTecnicaObra>
          </MyAniLink>
        </ContBackObras>
      </Container>

      <Columns isMultiline className="columns-responsive margin-no full-height_ padding-3 cols-cont-images">

        {obras.nodes.map((obra) => {
          //console.log('index => ', index);
          //console.log('obra => ', obra);

          if (obra.status === 1) {
            return (
              <Column
                className='padding-no padding-3 max-height-col__ col-cont-image'
                // isSize={{mobile: 6, tablet: 4, desktop: 2, widescreen: 2}}
                key={obra.id_obra}
              >
                <ContItem
                  id={obra.url}
                  src={`${PATH_IMG}/m_${obra.file_name}`}
                >
                  <LinkObrasAniLink
                    paintDrip
                    hex={GlobalBackgroundColors.bgContentLight}
                    to={`/${obra.subject_min}/${obra.url}`}
                  >
                    <ContItemName>
                      {obra.title}
                    </ContItemName>
                  </LinkObrasAniLink>
                </ContItem>
              </Column>
            )
          }else{
            return null
          }
        })}
      </Columns>
    </Layout>
  )
}

export default ObrasPage
